// Insert your styling here.
html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 348px;
}
.cover () {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.no-padding {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

div.commerce_add_to_cart_confirmation_overlay {
 z-index: 999;
}

body.navbar-is-fixed-top {
  padding-top: 118px !important;
}
body.admin-menu.navbar-is-fixed-top {
  padding-top: 147px !important;
}
body.admin-menu.navbar-is-static-top {
  padding-top: 28px !important;
}

.region.region-sidebar-first {
  .block-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #6a6a6a;
    z-index: 10;
    position: relative;
  }
}


.front .main-container .entity-bean,
.front .main-container .view-header {
  font-size: 16px;
  line-height: 1.7;
  p {
    font-size: 16px;
    line-height: 1.7;
  }
}

.btn {
  text-transform: uppercase;
  padding: 12px 20px;
  font-weight: bold;
  &:hover {
    background-color: @brand-secondary;
    border-color:  @brand-secondary;
    color: #fff;
  }
  &.btn-secondary {
    color: #fff;
    background-color: @brand-secondary;
    &:hover {
      background-color: #1e1e1e;
    }
  }
}

.region.region-header {
  .row();
}

.region-highlighted {
  .row();
  background-color: #f8f8f8;
}
.view-frontpage-slideshow {
  .header-bg {
    height: 546px;
    .cover();
  }
  .flex-active-slide {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  /* hide nav buttons */
  .flex-direction-nav {
    display: none;
  }
  .flex-caption {
    background-color: #f9f9f9;
    position: absolute;
    left: 25px;
    bottom: 25px;
    color: #272727;
    padding-left: 40px;
    padding-right: 40px;
	padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 25px;
    a {
      color: #272727;
      text-decoration: none;
    }
  }
}
#block-views-front-block {
  .view > .view-content {
    padding-top: 15vh;
    padding-bottom: 10vh;
  }
  h1 {
    &:after {
      content: "";
      width: 40%;
      display:block;
      border-bottom: 4px solid @brand-success;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

#block-bean-contact-form {
  background-color: #1f1f1f;
  color: #eaeaea;
  padding-bottom: 120px;
  .block-title {
    text-align: center;
    text-transform: uppercase;
    color: #f7f7f7;
    font-weight:bold;
    margin-top: 0;
    margin-bottom: 40px;
    line-height: 1;
  }
  .field.field-name-field-text {
    text-align:center;
  }
  .field.field-name-title-field {
    font-size: 18px;
    text-transform: uppercase;
    color: #b1b1b1;
    font-weight:bold;
    margin-bottom: 40px;
    line-height: 1;
  }
  overflow: hidden;
  .btn {
    .btn-block;
  }
  &:before {

  }
  .entityform {
    .grippie {
      display: none;
    }
    .form-control {
      border-color: #4d4d4d;
      &:focus {
        border-color: #bdbdbd;
      }
    }
    .form-control.form-text {
      height: 50px;
      background-color: #1f1f1f;
    }
    #edit-field-contact-name {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-user;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    #edit-field-contact-email {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-envelope;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    #edit-field-contact-phone {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-phone;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    #edit-field-contact-message {
      position:relative;
      &:after {
 		   font-family: FontAwesome;
 			 content: @fa-var-comments;
       display: inline-block;
       position: absolute;
       top: 15px;
       right: 10px;
       color:#969595;
      }
    }
    textarea.form-control {
      min-height: 150px;
      background-color: #1f1f1f;
    }
  }
  .field-name-map-block {
    margin-top: 62px;
    color: #000;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
    	  -webkit-filter: grayscale(99%);
  }
}

.flexslider {
  margin: 0px;
  background: none;
  border: none;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.flex-direction-nav a:before {
  line-height: 40px;
  color: rgba(39, 39, 39, 0.8);
}
.flex-control-nav {
  width: auto;
  position: absolute;
  bottom: 25px;
  z-index: 5;
  right: 20px;
}
.flex-control-paging li a {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  background: #666;
  background: rgba(249,249,249,1);
}
.flex-control-paging li a.flex-active {
  background: #666;
  background: rgba(39,39,39,1);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}



.view.view-poolemployees {
  .view-header {
    text-align:center;
    margin-bottom: 30px;
    position: relative;
    z-index: 10;
  }
  .view-content {
    .slides li {
      > .node {
        height: 405px;
        margin: 15px;
        overflow: hidden;
        color: #eaeaea;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -ms-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;

        .group-footer {
          text-align: center;
          height: 110px;
          background-color: #1f1f1f;
          padding-top: 20px;
          padding-bottom: 20px;
          font-size: 14px;
          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          -ms-transition: all .5s;
          -o-transition: all .5s;
           transition: all .5s;
           .field-name-title-field {
             text-transform:uppercase;
             font-weight: bold;
             margin-bottom: 15px;
           }
           .field-name-body {
             p {margin: 0;}
             a {
               font-style:italic;
               color: #b3d92f;
               text-decoration: none;
               &:hover {
                 text-decoration: underline;
               }
             }
           }
        }
      }
      &:hover {
        > .node {
          margin-top: 0;
          margin-bottom: 0;
          .group-footer {
            padding-top: 40px;
            padding-bottom: 40px;
            height: 200px;
            background-color: @brand-success;
          }
        }
      }
    }
  }
}

#block-views-poolemployees-block-1 {
  color: #eaeaea;
  position:relative;
  //overflow: hidden;
  padding-top: 150px;
  padding-bottom: 150px;
  margin-top: 120px;
  margin-bottom: 150px;
  > * {
    /*-webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: skewY(7deg);
    -ms-transform: skewY(7deg);
    transform: skewY(7deg);*/
  }
  .block-title {
    text-align: center;
  }
}
.flexslider .flex-direction-nav .flex-prev,
.flexslider .flex-direction-nav .flex-next {
  outline:none;
}

#block-views-frontpage-blocks-block {
  .block-title {
    text-align:center;
    margin-bottom: 60px;
  }
  .view.view-frontpage-blocks {
    text-align:center;
    .view-content {
      width: 100%;
      .views-row {
        .make-sm-column(6);
        .no-padding;
        .row-bg {
          height: 546px;
          .cover();

        }
      }
    }
  }
  .field.field-name-title-field {
    h4 {
      margin-top: 25px;
      font-weight: 700;
    }
  }
  .field.field-name-body {
    font-style:italic;
    margin-bottom: 30px;
  }
  .field.field-name-field-fp-block-image {
    display:inline-block;
    background: @brand-success;
    width: 180px;
    height: 180px;
    line-height: 180px;
    border-radius: 50%;
    text-align:center;
    img {
      display:inline-block;
    }
  }
}

#block-views-facebook-block {
  .view-facebook {
    .views-bootstrap-grid-plugin-style {
      > .row > .col {
        margin-bottom: 30px;
        > .node-teaser {
            .field-name-field-image {
              position:relative;
              z-index:1;
              &:before {
                content: "";
                position:absolute;
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                background-color: rgba(121,116,182,0.5);
                opacity: 0;
                -moz-transition: opacity 0.5s ease-in-out;
                -webkit-transition: opacity 0.5s ease-in-out;
                -ms-transition: opacity 0.5s ease-in-out;
                -o-transition: opacity 0.5s ease-in-out;
                transition: opacity 0.5s ease-in-out;
              }
            }
            &:hover {
              cursor: pointer;
              .field-name-field-image {
                &:before {
                  opacity: 1;
                }
              }
            }
          .group-footer {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

/* Share this */
.sharethis-buttons {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: right;
}
.sharethis-buttons span {
  color: #c6c6c6;
  border: 1px solid;
  border-color: #e7e7e7;
  display: inline-block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  &:hover {
    border-color: #c2c2c2;
  }
}
.st_sharethis_custom {
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: @fa-var-share-alt;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_twitter_custom {
  &:hover {
    cursor: pointer;
    color: #00cdeb;
  }
  &:before {
    content: @fa-var-twitter;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_facebook_custom {
  &:hover {
    cursor: pointer;
    color: #3B5998;
  }
  &:before {
    content: @fa-var-facebook;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_googleplus_custom {
  &:hover {
    cursor: pointer;
    color: #dd4b39;
  }
  &:before {
    content: @fa-var-google-plus;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}
.st_pinterest_custom {
  &:hover {
    cursor: pointer;
    color: #C92228;
  }
  &:before {
    content: @fa-var-pinterest-p;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:20px;
    color: inehrit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
}

.not-front .node .flexslider {
  position: relative;
  .flex-caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #eaeaea;
    text-shadow: 1px 1px 6px rgba(104, 104, 104, 1);
    opacity: 0;
  }
  &:hover {
    .flex-caption {
      opacity: 1;
    }
  }
}

#block-menu-block-2 {
  .menu li {
    a {
      color: @text-color;
      border-top: 1px solid #e3e3e3;
      padding: 10px 0px;
      &:after {
        content: "\00BB";
        margin-left: 5px;
      }
      &:hover {
        color:#969595;
        background-color: transparent;
        &:after {
          color: @brand-success;
        }
      }
    }
  }
}

.not-front {
  .main-container {
    > .row {
      //overflow: hidden;
    }
    aside {
      padding-top: 100px;
    }
    section.col-sm-9 {
      background-color: #f6f6f6;
      padding-top: 100px;
      /*padding-bottom: 100%;
      margin-bottom: -100%;*/
      @media (min-width: @screen-md-min) {
         min-height: 925px;
      }
      @media (min-width: @screen-lg-min) {
        min-height: 925px;
        padding-left: 60px;
        padding-right: 60px;
      }
    }
    .node.view-mode-full {
        background-color: #fff;
    }
  }
  .region.region-content {
    padding-bottom: 100px;
  }
  .block-title {
    font-weight: 600;
    font-size: 18px;
    color: @brand-success;
  }
  &.node-type-site-contact-form {
	.region.region-content {
      padding-bottom: 0px;
  	}
  }
}
/* Popular products */
#block-views-popular-block {
  margin-top:50px;
  margin-bottom: 30px;
  .block-title {
    margin-bottom: 15px;
    color: #333;
  }
 .view-popular {
   .view-content {
     .col {
       margin-bottom: 30px;
     }
   }
 }
}

/* Cart confirm message */
div.messages.commerce-add-to-cart-confirmation {
  z-index: 1000;
  padding-bottom: 10px;
  color: #838383;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border: 0;
  .content {
    font-style: italic;
  }
  .button-wrapper {
     z-index: 1000;
  }
  .commerce-product-title-label,
  .commerce-product-status-label {
   float:left;
  }
}
div.messages.commerce-add-to-cart-confirmation .message-inner .button-wrapper {
  padding: 50px 10px;
  background-color: #fff;
  width: auto;
}

div.messages.commerce-add-to-cart-confirmation .message-inner .button-wrapper .button {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: bold;
  &.checkout {
    background-color: @brand-secondary;
    a {
      text-decoration: none;
    }
  }
  &.continue {
    background-color: #f6f6f6;
    color: @brand-secondary;
  }
}
div.messages.commerce-add-to-cart-confirmation .message-inner .added-product-title {
  width: auto;
  text-align: left;
  color: @brand-secondary;
  position: relative;
  z-index: 1001;
}
div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content .views-field-commerce-total .field-content {
  color: @brand-secondary;
}

/* Facetapi Filter */

.facetapi-facetapi-checkbox-links {
  li {
    a {
      text-decoration: none;
      color: #595959;
    }
    input[type="radio"], input[type="checkbox"] {
      margin-right: 10px;
    }
  }
}
.search-api-ranges-widget {
  #edit-submit {
    visibility: hidden;
  }
  .control-label{
    display: none;
  }
  .form-control {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
  input[type="text"], input[type="text"]:focus{
    outline: 0;
    border:none;
    box-shadow:none;
  }
  .form-item.form-item-range-to {
    position:relative;
    &:after {
      content: "€";
      content: "€";
      position: absolute;
      top: 7px;
      right: -10px;
    }
    .form-control {
      text-align:right;
    }
  }
  .range-slider-box {
    height: 20px;
    .ui-slider-horizontal {
      height: 4px;
      border-radius: 0;
      background:#d8d8d8;
      border: 0;
      margin:10px 0;
      &:before {
        content: "";
        background-color: #d8d8d8;
        display: block;
        height: 20px;
        width: 2px;
        position: absolute;
        top: -5px;
        left:0px;
      }
      &:after {
        content: "";
        background-color: #d8d8d8;
        display: block;
        height: 20px;
        width: 2px;
        position: absolute;
        top: -5px;
        right: 0;
      }
    }
    .ui-slider-handle {
      top: -.5em;
      margin-left: -.1em;
    }
  }
  .ui-widget-header {
    background: @brand-secondary;
  }
  .ui-slider .ui-slider-handle {
    width: 5px;
    background: @brand-secondary;
    border-color: @brand-secondary;
  }
}

.views-exposed-form {
  .views-exposed-widget {
    .pull-right;
    margin-bottom: 30px;
  }
  .form-type-select {
    display:table;
    .control-label {
      display:table-cell;
      padding-right: 10px;
      font-weight: normal;
      color: #9a9a9a;
    }
    .form-control.form-select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../images/arrow.png?v=001') no-repeat right center;
      padding: 0px 50px 0px 12px;
      height: 30px;
    }
  }
}




/* Products */

.view-all-products-search-api,
.view-popular,
.view-product-categories-catalog {
  .node-teaser {
    background-color: #fff;
    margin-bottom: 30px;

    .field-name-title-field {
      float: left;
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .commerce-product-field-commerce-price {
      float: right;
      padding-right: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}



.commerce-add-to-cart {
  .table-responsive {
    border: 0;

    * {
      border: 0;
    }
  }
  > div {
    .form-wrapper {
      text-align:left;
    }
  }
   .form-item-quantity {
     width: 150px;
     /*display: inline-block;*/
     margin-right: 15px;

     #edit-quantity {
       /*display:inline-block;*/
       max-width: 100px;
     }
   }
   .btn.btn-default {
     color:#fff;
     background-color: @brand-secondary;
     border-color: @brand-secondary;
     &:hover {
       background-color: #1e1e1e;
     }
   }
   .form-item-attributes-field-color {
     display:inline-block;
     .img-responsive {
       border-radius: 15px;
     }
     .radio input[type="radio"] { display: none;}
     .control-label {
       padding-left: 0;
       padding-right: 15px;
     }
   }
}

.node-product,
.node-accessories,
.node-handicraft,
.node-yarn {
  &.view-mode-full {
    .productinfo {
      padding-top: 40px;
      padding-left: 25px;
      padding-right: 40px;
      padding-bottom: 40px;
    }
    .form-item-attributes-field-size {
      max-width: 240px;
    }
    .view-flexslider-thumbs {
      background-color: #f6f6f6;
    }
    .field-name-body {
      margin-bottom: 30px;
    }
    .field {
      margin-bottom: 15px;
      .field-label {
        margin-bottom: 7px;
      }
    }
    .field-name-commerce-price {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .field-name-field-laundry-guide {
      display: inline-block;
      margin-right: 15px;
      vertical-align: top;
      margin-bottom: 0;
      > .field-items {
        > .field-item {display: inline-block; margin-right: 7px;}
      }
    }
    .field-name-field-knitting-needles {
      display: inline-block;
      vertical-align: top;
    }
    .field-name-field-mesh-density {
      display: inline-block;
      vertical-align: top;
    }
    h1 {
      margin-top: 0;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .btn.btn-success {
      background-color: #fff;
      border-color: #333333;
      color: #333333;
      text-transform: none;
      font-weight: normal;
      &:hover {
        color:#fff;
        background-color: @brand-secondary;
        border-color: @brand-secondary;
      }
    }
  }
}
.node-yarn {
  .form-item-attributes-field-color.form-type-radios.form-group {
    > .control-label {
      visibility: hidden;
      position: absolute;
    }
  }
}

.ctools-modal-dialog .modal-body {
  .view-verse-selection {
    .row {
      margin-bottom: 30px;
    }
  }
  #edit-ervw-submit {
    position:absolute;
    top: 10px;
    right: 10px;
  }
}

#block-bean-fond-block {
  .field-name-field-text {
     margin-bottom: 30px;
    em {
      font-family: "Times New Roman", Times, serif;
      font-size: 30px;
    }
  }
}

.view-commerce-cart-form {
  .btn-info, .btn-default {
    color:#fff;
    background-color: @brand-secondary;
    border-color: @brand-secondary;
    &:hover, &:focus, &.focus {
      background-color: #1e1e1e;
      color: #fff;
    }
  }
  .btn-danger {
    background-color: #fff;
    border-color: #dddddd;
    color: #333333;
    text-transform: none;
    font-weight: normal;
    &:hover {
      color:#fff;
      background-color: @brand-secondary;
      border-color: @brand-secondary;
    }
  }
  .table {
    table-layout:fixed;
    td {
      word-wrap: break-word;
    }
  }
}

.group-greeting,
.group-cardtext {
  h3 {
    color: #3b3298;
    font-size: 17px;
    font-weight: 600;
  }
}

.view-commerce-cart-summary {
  .table {
    table-layout:fixed;
    td {
      word-wrap: break-word;
    }
  }
}
.table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
  white-space:normal;
}


/* Stacktable */

.stacktable { width: 100%; }
.st-head-row { padding-top: 1em; }
.st-head-row.st-head-row-main { font-size: 1.5em; padding-top: 0; }
.st-key { width: 49%; text-align: right; padding-right: 1%; }
.st-val { width: 49%; padding-left: 1%; }



/* RESPONSIVE EXAMPLE */

.stacktable.large-only { display: table; }
.stacktable.small-only { display: none; }
.stacktable.small-only .views-align-right {
  text-align:left;
}
@media (max-width: 800px) {
  .stacktable.large-only { display: none; }
  .stacktable.small-only { display: table; }
}

.node-image-gallery {
  .field-name-body {
    margin-bottom: 30px;
  }
  .field-name-field-images {
    height: 85px;
    margin-bottom: 15px;
  }
}

.page-node {
  .node-page {
    > .row {
      .top {
        padding-top:50px;
        text-align:center;
        .field-name-field-title-2 {
          text-transform: uppercase;
        }
        .field-name-title-field {
        }
        h1 {
          margin-bottom: 30px;
        }
      }
      .middle {
         padding-top:50px;
         padding-bottom:50px;
         .field-name-body {
           padding-right: 30px;
           padding-left: 30px;
           max-width: 900px;
           margin: 0 auto;
         }
      }

    }
    .field-name-field-link {
      margin-top: 30px;
    }
  }
}


.not-front.page-node {
  .node-permanent-page {
    > .row {
        padding-top:50px;
        .field-name-field-title-2 {
          text-transform: uppercase;
        }
        .field-name-title-field {
          text-align: center;
          margin: 0 auto;
        }
        h1 {
          margin-bottom: 30px;
        }

     .field-name-body {
       padding-top:50px;
       padding-bottom:50px;
       padding-right: 30px;
       padding-left: 30px;
       max-width: 900px;
       margin: 0 auto;
      }

    }
    .field-name-field-link {
      margin-top: 30px;
    }
  }
}

/* Form */

.webform-client-form {
  margin-top: 30px;
}


/* Welcome text */
#block-bean-valkommen {
  .block-text {
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }
  .block-bg-image {
    height: 410px;
    text-align: center;
    .cover();
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .field-name-field-text {
    p {
      font-weight: 300;
    }
  }
  .field-name-field-link {
    position:relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
    padding: 5px;
    border: 1px solid #f3f3f3;
  }
}

#block-bean-oppettider {
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  .field-name-field-info-text {
    margin-bottom: 30px;
  }
  .field-name-field-image {
    padding-bottom: 15px;
  }
}

/* Catalog menu */

/* Block menu catalog */
#block-menu-menu-catalog {
  text-transform: uppercase;
	.menu {
		font-size: 13px;
		font-weight: bold;
    > li {
      margin-bottom: 15px;
    }
	}
	//dropdown
	ul.menu li.expanded.active-trail ul.dropdown-menu {
	  display: block;
	}
	ul.menu li.dropdown ul.dropdown-menu {
    @media (min-width:@grid-float-breakpoint) {
      display:block; // show menu expanded all the time
    }
	  margin-top: 0;
	  position:relative;
	  width: 100%;
	  margin-bottom: 15px;
	  background-color: transparent;
	  border: 0 none;
	  -webkit-box-shadow: none;
	  -moz-box-shadow: none;
	  box-shadow: none;
	  > li > a {
		  padding: 5px 0px;
	  }
	}
	.menu > li > a {
		color: #5f443c;
		text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
		&:focus {
			outline: 0;
		}
    &.active {
      color: #5fb2a3;
    }
	}
	.dropdown-menu > li > a {
		color: #5f443c;
    font-size: 14px;
		&:focus {
			outline: 0;
		}
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		color: #5fb2a3;
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		background-color: transparent;
	}
	.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
		background-color: transparent;
    color: #5fb2a3;
	}
	.menu > li > a:hover, .menu > li > a:focus {
		color: #5fb2a3;
	}
  .caret {
    display:none;
  }
}

#block-views-categories-block {
  text-transform: uppercase;
  @media (min-width: @screen-lg-min) {
  	padding-left: 90px;
  }
  padding-bottom: 60px;
  ul {
    list-style:none;
	padding-left: 0;
    > li {
      > a {
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        color: #272727;
        line-height: 2;
        &:hover,
        &.active {
          color: #6fbc80;
        }
        }
        > ul {
          padding: 0;
          > li {
            a {
              font-size: 13px;
              font-weight: normal;
              text-decoration: none;
              color: #272727;
              &:hover,
              &.active {
                color: #6fbc80;
              }
            }
          }
        }
    }
  }
}


/* Sidebar menu */
#block-menu-menu-sidebar-menu {
	.menu {
		font-size: 13px;
		font-weight: bold;
    > li {
      margin-bottom: 15px;
    }
	}
	//dropdown
	ul.menu li.expanded.active-trail ul.dropdown-menu {
	  display: block;
	}
	ul.menu li.dropdown ul.dropdown-menu {
	  margin-top: 0;
	  position:relative;
	  width: 100%;
	  margin-bottom: 15px;
	  background-color: transparent;
	  border: 0 none;
	  -webkit-box-shadow: none;
	  -moz-box-shadow: none;
	  box-shadow: none;
	  > li > a {
		  padding: 5px 0px 5px 15px;
	  }
	}
	.menu > li > a {
		color: #5f443c;
		text-transform: uppercase;
    text-decoration: none;
    font-size: 16px;
		&:focus {
			outline: 0;
		}
    &.active {
      color: #5fb2a3;
    }
	}
	.dropdown-menu > li > a {
		color: #5f443c;
    font-size: 13px;
		&:focus {
			outline: 0;
		}
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		color: #5fb2a3;
	}
	.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
		background-color: transparent;
	}
	.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
		background-color: transparent;
    color: #5fb2a3;
	}
	.menu > li > a:hover, .menu > li > a:focus {
		color: #5fb2a3;
	}
}


/* blog */
.page-blog {
  .page-header {
    font-size: 21px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.node-blog-post {
  .field-name-comments-count {
    display: inline-block;
    margin-right: 30px;
    a {
      text-decoration: none;
      color: @text-color;
    }
    &:hover {
      cursor:pointer;
    }
    i {
      font-size: 150%;
      margin-right: 5px;
    }
  }
  .flag-outer-like {
    display: inline-block;
    a {
      text-decoration: none;
      color: @text-color;
    }
    &:hover {
      cursor:pointer;
    }
    i {
      font-size: 150%;
    }
    .flag-title {
      display:none;
    }
  }
}
.not-front .main-container .node.node-blog-post{
  background-color: transparent;
  > .row {
    > div {
      max-width: 993px;
      padding: 30px 50px;
      background-color: #fff;
    }
  }
}
.not-front .main-container .node.node-blog-post.view-mode-full {
  background-color: transparent;
  > .row {
    > div {
      margin-bottom: 45px;
    }
    &:last-child {
      //margin-top: 30px;
    }
  }
}
.node-blog-post {
  h1 {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px;
    a {
      text-decoration: none;
    }
  }
  .field-name-field-image {
    margin-bottom: 30px;
  }
  .field-name-field-images{
    margin-bottom: 30px;
  }
  #flexslider-1 {
    margin-bottom: 30px;
  }

  .middle {
    text-align:right;
    padding: 30px 50px;
    .field-name-submitted-by {
      display: inline-block;
      margin-right: 30px;
    }
  }
}
.view-blog.view-display-id-page {
  .node-blog-post {
    background-color: #fff;
    margin-bottom: 30px;
    .block-text {
      padding-top: 40px;
      padding-left: 30px;
      padding-right: 50px;
      padding-bottom: 30px;
    }
  }
  .field-name-title-field {
    text-transform: uppercase;
    a {
      color: @text-color;
      text-decoration: none;
    }
    h4 {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
  .field-name-submitted-by {
    text-transform: uppercase;
    color: #000000;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 30px;
    a {
      color: #000000;
      text-decoration: none;
    }
  }
  .field-name-body {
    margin-bottom: 30px;
  }
}

#block-views-blog-block-2 {
  padding-left: 30px;
  .views-row {
    margin-bottom: 30px;
    .field-name-title-field {
      a {
        color: #272727;
        text-decoration: none;
        &:hover {
          color: #5fb2a3;
        }
      }
    }
    .field-name-post-date {
      font-size: 12px;
      color: #afafaf;
    }
  }
}
#block-views-blog-block-1 {
  padding-left: 30px;
  .views-summary {
    padding: 0;
    list-style:none;
    li {
      list-style:none;
    }
  }
}

/* Comments */
#comment-form {
  .row();
}

.comment-wrapper {
  padding: 25px;
  .comment {
    margin-bottom: 60px;
    padding-left: 20px;
  }
  .submitted {
    font-style: normal;
    margin-bottom: 15px;
  }
  h2 {
    margin-bottom: 30px;
  }
  .comment-form {
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
       color: #272727;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: #272727;
       opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: #272727;
       opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: #272727;
    }
    :placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
      color: #272727;
    }
    .form-control {
      text-transform: uppercase;
    }
    .form-item {
      &.form-item-name, &.form-item-mail, &.form-type-textarea {
        .control-label {
          text-indent: -9999px;
          position:absolute;
        }
      }
      &.form-item-name, &.form-item-mail {
        .make-sm-column(6);
      }
    }
    .field-name-comment-body  {
      .make-sm-column(6);
      .make-sm-column-push(6);
    }
    .captcha {
      .make-sm-column(6);
      .make-sm-column-pull(6);
      &.panel {
        border:0;
        .panel-heading {
          border: 1px solid #cccccc;
        }
        .panel-body {
          border-left: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
          border-bottom: 1px solid #cccccc;
        }
      }
    }
    .form-actions {
      text-align: right;
      .btn.btn-default {
        background-color: #393939;
        color: #fff;
      }
    }
  }
}
/* Contact page */

.node-site-contact-form {
  > .row > .container {
    .top {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 50px;
      .field-name-field-title-2 {
        text-transform: uppercase;
      }
    }
    .middle {
      padding-top: 50px;
      .field-name-field-title-3 {
        margin-bottom: 80px;
      }
    }
    .field-name-body,
    .field-name-field-body-right {
      text-align: center;
    }
    .field-name-field-title-3 {
      text-align: center;
    }
  }
  .row.central {
    background-color: #f6f6f6;
    margin-top: 120px;
  }
  .webform-client-form {
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
       color: #272727;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: #272727;
       opacity:  1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
       color: #272727;
       opacity:  1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
       color: #272727;
    }
    :placeholder-shown { /* Standard (https://drafts.csswg.org/selectors-4/#placeholder) */
      color: #272727;
    }
    .form-control {
      background-color: transparent;
      text-transform: uppercase;
      color: #272727;
      box-shadow: none;
    }
    @media (min-width: @screen-sm-min) {
      display: table;
    }
    margin-bottom: 80px;
    .webform-component--name {
      @media (min-width: @screen-sm-min) {
        display:table-cell;
        padding-right: 15px;
        padding-bottom: 15px;
      }
      .form-control {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        box-shadow: none;
      }
    }
    .webform-component--email {
      @media (min-width: @screen-sm-min) {
        display:table-cell;
        padding-left: 15px;
        padding-bottom: 15px;
      }
      .form-control {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        box-shadow: none;
      }
    }
    .webform-component--subject {
      padding-bottom: 15px;
      .form-control {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        box-shadow: none;
      }
    }
    .webform-component--message {
      padding-bottom: 15px;
    }
    .grippie {
      display:none;
    }
    .webform-submit {
      width: 100%;
    }
  }
  .field-name-field-map {
    color: #000;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 10+ */
        filter: gray; /* IE6-9 */
    	  -webkit-filter: grayscale(99%);
  }
}


/*Instagram */

#block-views-instagram-block {
  padding-top: 80px;
  padding-bottom: 160px;
  @media (min-width: @screen-lg-min) {
    padding-left: 60px;
    padding-right: 60px;
  }
  .col {
    margin-bottom: 30px;
  }
}

/* Commerce add to cart confirmation */

div.messages.commerce-add-to-cart-confirmation .view-confirm-message-product-display .view-content .field .views-label {
  float: none;
  font-size:15px;
}

/* Mail */

#mimemail-body div {
  font-size: 14px;
}

/* Collapse sidebar */

#collapsible {
  padding-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
#collapsible.in,
#collapsible.collapsing {
    display: block!important;
}
.toggle-button {
  margin-bottom:15px;
}
.toggle-button .show-ctrl{
    display: none;
}
.toggle-button .hide-ctrl{
    display: block;
}
.toggle-button.collapsed .show-ctrl{
    display: block;
}
.toggle-button.collapsed .hide-ctrl{
    display: none;
}

/* Comments */

.group-comments.panel {
  border: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  > .panel-heading {
	background-color: transparent;
    border: 0;
  }
  .panel-title.fieldset-legend {
    font-size: 25px;
  }
}

img.media-element.file-default {
  max-width: 100%;
  height:auto;
}

.form-item-attributes-field-color {
	.ajax-progress {
		position:absolute;
		top: 0;
		left: 0;
		background-color: rgba(255,255,255,.5);
	}
}


a.contextual-links-trigger {
  background-color: black;
}

.form-type-checkbox,
.form-checkboxes {
  a {
    text-decoration: underline;
  }
}

#sliding-popup {
  .popup-content {
    padding-top: 10px;
    #popup-buttons button {
      .btn;
      .btn-default;
      background-color: transparent;
      padding: 8px 25px;
      color: #fff;
      border-color: #fff;
      text-shadow: none;
      &:hover, &:focus {
        background-color: transparent;
        border-color: #fff;
      }
    }
    .popup-text {
      a {
        text-decoration: underline;
      }
    }
  }
}

.g-recaptcha {
  margin-bottom: 15px;	
}	
