// Insert your styling here.
.navtop {
  background-color: #f6f6f6;
  color: @brand-secondary;
  border-bottom: 1px solid #e4e4e4;
  .block {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  em {
    font-style:normal;
    color: #fff;
  }
  .region-navigation {
    
  }
}
.affix {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  .navtop {
    display: none;
  }
}

.nav-container {
  letter-spacing: 0.7px;
  .container-fluid();
}

#block-locale-language {
  li > a {
    &.active {
      font-weight: bold;
    }
  }
}

/* Phone number block header */
/*#block-bean-telefonnummer {
  border-right: 1px solid #e4e4e4;
  padding-right: 20px;
  background: url('../images/phone.png') no-repeat left;
  padding-left: 30px;
}*/

#block-commerce-cart-cart {
  border-right: 1px solid #e4e4e4;
}
.region.region-navigation {
  text-align: right;
  vertical-align: top;
  display: inline-block;
  @media (max-width: @grid-float-breakpoint-max){
    .pull-right;
  }
  .block {
    vertical-align: top;
    display: inline-block;
    text-align: left;
  }
  #block-locale-language {
    
  }
  #block-bean-open-hours {
    vertical-align: middle;
  }
  #block-bean-social-links {
    vertical-align: top;
    text-align:right;
    color: #cccccc;
    .field {
      &.field-name-title-field {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-right: 15px;
      }
      display: inline-block;
      &.field-name-field-links {
        .field-item {
          display: inline-block;
          font-size: 28px;
          margin-right: 15px;
          a {
            background-color: #121212;
            color: #5e5e5e;
            //border-radius: 50%;
            display: inline-block;
            width: 48px;
            height: 48px;
            line-height: 48px;
            text-align:center;
            vertical-align: middle;
            &.twitter {
              &:hover {
                background-color: #55acee;
                color: #fff;
              }
            }
            &.facebook {
              &:hover {
                background-color: #608cd7;
                color: #fff;
              }
            }
            &.instagram {
              &:hover {
                background-color: #517fa6;
                color: #fff;
              }
            }
            &.linkedin {
              &:hover {
                background-color: #006699;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
	.language-switcher-locale-url {
    padding-left: 15px;
		list-style: none;
    margin: 0;
		li {
			list-style: none;
			display: inline;
      &.first {
        a {
          
        }
      }
			a {
        margin-right: 5px;
				display:inline-block;
        color: #2a2a2a;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				&:hover, &.active {
					
				}
			}
		}
	}
}
body.admin-menu.adminimal-menu::before {
  height: auto;
}
.navbar {
  .navbar-btn {
    margin: 0;
  }
  .logo {
    @media (min-width: @grid-float-breakpoint){
      position:relative;
      top: 30px;
    }
    @media (min-width:@screen-lg-min) {
      position:relative;
      left: 125px;
    }
    
    img {
      max-width: 190px;
      height: auto;
    }
  }
  &.affix {
    
  }
  margin-bottom: 0;
  .navbar-nav {
    > li {
      > a {
        text-transform: uppercase;
        font-weight: normal;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .navbar-collapse.collapse {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media (min-width: @grid-float-breakpoint){
    //height: 118px;
    &.affix {
      //height: 80px;
    }
    .nav-container {
      display: -webkit-flex;
      display: flex;
    }
    .navbar-header {
      margin-right: 70px;
    }
    .navbar-header,.navbar-brand,.navbar .navbar-nav,.navbar .navbar-nav > li {
      float: none;
      display: inline-block;
    }
    .region.region-navigation {
     
    }
    .navbar-collapse.collapse {
      width: 100%;
      clear: none;
      display: inline-block;
      text-align:center;
      
    }
    .navbar-nav {
      //.pull-right;
      display: inline-block;
      float:none;
      vertical-align:top;
      > li {
        > a {
          @media (min-width: @screen-lg-min) {
            
          }
          line-height: 20px;
          &.active, &:hover {
            /*-webkit-box-shadow: inset 0 -5px 0 0 @brand-success;
            -moz-box-shadow: inset 0 -5px 0 0 @brand-success;
            box-shadow: inset 0 -5px 0 0 @brand-success;*/
          }
        }
      }
    }
  }
}
//dropdown
#navbar {
  /*-webkit-box-shadow: 0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow:    0px 0px 5px 0px rgba(50, 50, 50, 0.75);
  box-shadow:         0px 0px 5px 0px rgba(50, 50, 50, 0.75);*/
	ul.nav li.dropdown:hover ul.dropdown-menu {
		/*display: block;*/
	}
  .dropdown-menu > li {
    background-color: #fff;
  }
  .dropdown-menu > li > a {
    padding: 12px 20px;
  }
  ul.nav li.dropdown ul.dropdown-menu {
    margin-top: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color:transparent;
    border: 0;
    padding: 0;
    @media (max-width: @grid-float-breakpoint){
	    position:relative;
	    width: 100%;
	    margin-bottom: 15px;
      //padding-left: 15px;
    }
    @media (min-width: @grid-float-breakpoint){
      border: 1px solid #ccc;
      /*&:before {
        content: "";
        display:block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #ffffff transparent;
        margin-left: 30px;
      }*/
    }
  }
  
  ul.nav li.dropdown:hover ul.dropdown-menu li.expanded ul.dropdown-menu {display:none;}
  ul.nav li.dropdown:hover ul.dropdown-menu li.expanded:hover ul.dropdown-menu {display:block;}
  @media (min-width: @grid-float-breakpoint){
    .dropdown-menu .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }
}
.navbar-toggle {
  top: 10px;
}

.nav > li > a {
  padding: 10px 15px;
}